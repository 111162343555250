exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".transitions__fade-enter___2ufjC {\n  opacity: 0; }\n\n.transitions__fade-enter--active___2L18F {\n  opacity: 1;\n  transition: opacity 200ms ease-out; }\n\n.transitions__fade-exit___2-0nF {\n  opacity: 1; }\n\n.transitions__fade-exit--active___oKzSB {\n  opacity: 0;\n  pointer-events: none;\n  transition: opacity 200ms ease-out; }\n", ""]);

// Exports
exports.locals = {
	"fade-enter": "transitions__fade-enter___2ufjC",
	"fade-enter--active": "transitions__fade-enter--active___2L18F",
	"fade-exit": "transitions__fade-exit___2-0nF",
	"fade-exit--active": "transitions__fade-exit--active___oKzSB"
};