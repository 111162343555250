exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Header__disabled___Gh7Xk {\n  pointer-events: none; }\n\n.Header__hidden___JXyng {\n  visibility: hidden; }\n\n.Header__leftHeader___3ARZV {\n  display: flex;\n  flex: 1;\n  justify-content: left;\n  align-items: center;\n  font-size: 16px;\n  text-align: left;\n  overflow: hidden; }\n\n.Header__rightHeader___3MyNH {\n  display: flex;\n  justify-content: right;\n  overflow: hidden; }\n\n.Header__collapsibleHeader___1PBe- {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  min-height: 48px;\n  padding: 8px 21px;\n  color: #778195;\n  letter-spacing: -0.2px; }\n\n.Header__collapsibleHeader___1PBe-:not([editable=true]):hover {\n  cursor: pointer; }\n\n.Header__collapsibleHeader___1PBe-[toggleable=false]:not([data-title-only=\"true\"]) {\n  padding-right: 0; }\n\n.Header__headerIcon___2CENw {\n  font-family: FontAwesome;\n  font-size: 20px;\n  text-align: left;\n  color: #79c2db;\n  margin-right: 15px; }\n\n.Header__headerTitle___2WWue {\n  height: 100%;\n  font-size: 16px;\n  text-align: left; }\n\n.Header__headerToggle___3u_WM {\n  padding-left: 20px;\n  font-family: FontAwesome;\n  border: none;\n  color: #557eff;\n  transform: rotateX(0);\n  transition: 0.5s transform; }\n\n.Header__toggleWrapper___14d-W {\n  border-left: solid 1px #e9e9e9;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 12px; }\n\n.Header__headerToggle___3u_WM[rotate=true] {\n  transform: rotateX(180deg); }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"disabled": "Header__disabled___Gh7Xk",
	"hidden": "Header__hidden___JXyng",
	"leftHeader": "Header__leftHeader___3ARZV",
	"rightHeader": "Header__rightHeader___3MyNH",
	"collapsibleHeader": "Header__collapsibleHeader___1PBe-",
	"headerIcon": "Header__headerIcon___2CENw",
	"headerTitle": "Header__headerTitle___2WWue",
	"headerToggle": "Header__headerToggle___3u_WM",
	"toggleWrapper": "Header__toggleWrapper___14d-W"
};