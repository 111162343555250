exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".TitleInput__title_wrapper___1Wj3F {\n  display: grid;\n  grid-row-gap: 5px;\n  flex: 1; }\n\n.TitleInput__title___3lldl {\n  font-size: 13px;\n  text-align: left;\n  font-weight: 500;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  color: #557eff;\n  display: block; }\n  .TitleInput__title___3lldl.TitleInput__title--disabled___e9Tga {\n    color: #c2c2c2; }\n  .TitleInput__title___3lldl.TitleInput__title--alert___1ij0r {\n    color: #f9453e; }\n\n.TitleInput__suffix___1w8E2 {\n  color: #778195;\n  font-weight: 300; }\n\n.TitleInput__prefix___e9Rqk {\n  font-weight: 300; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"title_wrapper": "TitleInput__title_wrapper___1Wj3F",
	"title": "TitleInput__title___3lldl",
	"title--disabled": "TitleInput__title--disabled___e9Tga",
	"title--alert": "TitleInput__title--alert___1ij0r",
	"suffix": "TitleInput__suffix___1w8E2",
	"prefix": "TitleInput__prefix___e9Rqk"
};