exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Arrow__arrow___VuyA0 {\n  margin: auto 10px auto auto;\n  font-size: 12px;\n  color: #557eff;\n  display: inline-block;\n  float: right; }\n  .Arrow__arrow___VuyA0.Arrow__arrow--disabled___1XZnk {\n    color: #dbdbdb; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"arrow": "Arrow__arrow___VuyA0",
	"arrow--disabled": "Arrow__arrow--disabled___1XZnk"
};