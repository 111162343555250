export const GET_REPORTS = 'GET_REPORTS';
export const GET_LAST_REPORTS = 'GET_LAST_REPORTS';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE';
export const CLEAR_ERROR_ON_GET_REPORTS = 'CLEAR_ERROR_ON_GET_REPORTS';

export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAILURE = 'GET_REPORT_FAILURE';

export const CLEAR_SELECTED_REPORT = 'CLEAR_SELECTED_REPORT';
export const CLEAR_REPORTS = 'CLEAR_REPORTS';
export const PROCESSING_GET_REPORTS = 'PROCESSING_GET_REPORTS';

export const GET_AGGREGATE_REPORTS = 'GET_AGGREGATE_REPORTS';
export const GET_AGGREGATE_REPORTS_SUCCESS = 'GET_AGGREGATE_REPORTS_SUCCESS';
export const GET_AGGREGATE_REPORTS_FAILURE = 'GET_AGGREGATE_REPORTS_FAILURE';


export const CREATE_BENCHMARK = 'CREATE_BENCHMARK';
export const CREATE_BENCHMARK_SUCCESS = 'CREATE_BENCHMARK_SUCCESS';
export const CREATE_BENCHMARK_FAILURE = 'CREATE_BENCHMARK_FAILURE';


export const EDIT_REPORT = 'EDIT_REPORT';
export const EDIT_REPORT_SUCCESS = 'EDIT_REPORT_SUCCESS';
export const EDIT_REPORT_FAILURE = 'EDIT_REPORT_FAILURE';


export const CLEAN_ALL_ERRORS = 'CLEAN_ALL_ERRORS';


export const ADD_REPORT_FOR_COMPARE = 'ADD_REPORT_FOR_COMPARE';
export const CLEAR_REPORT_FOR_COMPARE = 'CLEAR_REPORT_FOR_COMPARE';


