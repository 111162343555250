exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".pagination__pagination___2KvKo {\n  display: flex;\n  justify-content: flex-end;\n  align-items: baseline;\n  cursor: default;\n  padding-right: 20px;\n  margin: 16px 0;\n  user-select: none; }\n\n.pagination__description___2S5R5 {\n  position: relative;\n  line-height: 1.5;\n  font-size: 12px;\n  color: #778195; }\n  .pagination__description___2S5R5:not(:hover) {\n    overflow: hidden; }\n\n.pagination__switcher-reference___2JEVP {\n  pointer-events: none;\n  display: inline-block;\n  position: absolute; }\n\n.pagination__page-switcher___3CX7k {\n  opacity: 0;\n  transition: opacity 0.2s;\n  pointer-events: none;\n  position: absolute;\n  right: 0;\n  border-radius: 3px;\n  background-color: #ffffff;\n  box-shadow: 0 7px 40px 0 rgba(0, 0, 0, 0.2);\n  text-align: right;\n  padding: 16px;\n  white-space: nowrap;\n  color: #778195; }\n  .pagination__page-switcher___3CX7k[position='top'] {\n    bottom: -16px; }\n  .pagination__page-switcher___3CX7k[position='bottom'] {\n    top: -17px;\n    right: -16px; }\n  .pagination__page-switcher___3CX7k.pagination__hover___3jr_T {\n    opacity: 1;\n    pointer-events: all; }\n  .pagination__page-switcher___3CX7k div {\n    line-height: 20px;\n    cursor: pointer; }\n  .pagination__page-switcher___3CX7k [currentpage=true] {\n    font-weight: bold; }\n\n.pagination__arrows___Q1m49 {\n  display: inline-block; }\n  .pagination__arrows___Q1m49 i {\n    cursor: default;\n    font-size: 23px;\n    color: #dbdbdb; }\n    .pagination__arrows___Q1m49 i:first-child {\n      margin-right: 30px;\n      margin-left: 30px; }\n    .pagination__arrows___Q1m49 i[enabled=true] {\n      color: #555555;\n      cursor: pointer; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"pagination": "pagination__pagination___2KvKo",
	"description": "pagination__description___2S5R5",
	"switcher-reference": "pagination__switcher-reference___2JEVP",
	"page-switcher": "pagination__page-switcher___3CX7k",
	"hover": "pagination__hover___3jr_T",
	"arrows": "pagination__arrows___Q1m49"
};