exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__modal-content___3hhRI {\n  background-color: #fefefe;\n  margin: auto;\n  padding: 20px;\n  border: 1px solid #888;\n  width: 80%;\n  flex: 1; }\n\n.style__modal___3s9M0 {\n  display: block;\n  position: fixed;\n  /* Stay in place */\n  z-index: 999999;\n  /* Sit on top */\n  padding-top: 100px;\n  /* Location of the box */\n  left: 0;\n  top: 0;\n  width: 100%;\n  /* Full width */\n  height: 100%;\n  /* Full height */\n  overflow: auto;\n  /* Enable scroll if needed */\n  background-color: black;\n  /* Fallback color */\n  background-color: rgba(0, 0, 0, 0.4);\n  /* Black w/ opacity */ }\n\n.style__exit-icon___PZxy5 {\n  cursor: pointer;\n  font-size: 12px;\n  color: #c2c2c2; }\n\n.style__icon-wrapper___3-lYG {\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  justify-content: flex-end; }\n  .style__icon-wrapper___3-lYG svg {\n    position: absolute;\n    z-index: 2;\n    font-size: 22px;\n    color: #545454; }\n", ""]);

// Exports
exports.locals = {
	"modal-content": "style__modal-content___3hhRI",
	"modal": "style__modal___3s9M0",
	"exit-icon": "style__exit-icon___PZxy5",
	"icon-wrapper": "style__icon-wrapper___3-lYG"
};