exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".table-style__table___ZycYj {\n  background: white;\n  margin-top: 10px; }\n\n.table-style__thead___3n8IF {\n  color: var(--header-color) !important;\n  box-shadow: none !important;\n  border-bottom: solid 1px #e9e9e9 !important; }\n\n.table-style__th___2YPP0 {\n  padding: 0.8rem 0rem !important;\n  border-right: none  !important;\n  cursor: var(--cursor) !important;\n  flex: 20 0 auto !important;\n  outline: none !important; }\n\n.table-style__td___2Ton2 {\n  border-right: none !important;\n  padding: 0.3rem var(--cell-side-padding) !important; }\n  .table-style__td___2Ton2:not(.table-style__td--autoheight___3_uqJ) {\n    padding: 0 var(--cell-side-padding) !important;\n    line-height: var(--row-height); }\n\n.table-style__tr___3aZfi {\n  cursor: var(--cursor) !important;\n  background: var(--background-default);\n  color: var(--text-default); }\n  .table-style__tr___3aZfi:not(.table-style__tr--autoheight___2IqV7) {\n    line-height: var(--row-height); }\n  .table-style__tr___3aZfi.table-style__tr--selected___2SNAB {\n    background: var(--background-selected);\n    color: var(--text-selected); }\n\n.table-style__tr-group___3aK5U {\n  border-bottom: solid 1px #e9e9e9 !important; }\n\n.table-style__tbody___HWsUh {\n  overflow: visible !important; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"table": "table-style__table___ZycYj",
	"thead": "table-style__thead___3n8IF",
	"th": "table-style__th___2YPP0",
	"td": "table-style__td___2Ton2",
	"td--autoheight": "table-style__td--autoheight___3_uqJ",
	"tr": "table-style__tr___3aZfi",
	"tr--autoheight": "table-style__tr--autoheight___2IqV7",
	"tr--selected": "table-style__tr--selected___2SNAB",
	"tr-group": "table-style__tr-group___3aK5U",
	"tbody": "table-style__tbody___HWsUh"
};