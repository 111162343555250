exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ItemsList__list__item___I3vae {\n  cursor: pointer;\n  display: flex;\n  padding: 0 20px;\n  height: 24px;\n  align-items: center;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap; }\n  .ItemsList__list__item___I3vae:hover:not(.ItemsList__list__item--disabled___-DoMB) {\n    background: #557eff;\n    color: #ffffff; }\n  .ItemsList__list__item--disabled___-DoMB {\n    cursor: default; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"list__item": "ItemsList__list__item___I3vae",
	"list__item--disabled": "ItemsList__list__item--disabled___-DoMB"
};