
export const GET_PROCESSORS = 'GET_PROCESSORS';
export const GET_PROCESSORS_SUCCESS = 'GET_PROCESSORS_SUCCESS';
export const GET_PROCESSORS_FAILURE = 'GET_PROCESSORS_FAILURE';


export const CREATE_PROCESSOR = 'CREATE_PROCESSOR';
export const CREATE_PROCESSOR_SUCCESS = 'CREATE_PROCESSOR_SUCCESS';
export const CREATE_PROCESSOR_FAILURE = 'CREATE_PROCESSOR_FAILURE';


export const EDIT_PROCESSOR = 'EDIT_PROCESSOR';
export const EDIT_PROCESSOR_SUCCESS = 'EDIT_PROCESSOR_SUCCESS';
export const EDIT_PROCESSOR_FAILURE = 'EDIT_PROCESSOR_FAILURE';


export const DELETE_PROCESSOR = 'DELETE_PROCESSOR';
export const DELETE_PROCESSOR_SUCCESS = 'DELETE_PROCESSOR_SUCCESS';
export const DELETE_PROCESSOR_FAILURE = 'DELETE_PROCESSOR_FAILURE';


export const PROCESSORS_LOADING = 'PROCESSORS_LOADING';
export const CLEAN_ALL_ERRORS = 'CLEAN_ALL_ERRORS';
