exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__top___31smX {\n  padding: 10px 12px;\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap; }\n\n.style__bottom___3m7K_ {\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  flex: 1; }\n\n.style__top-inputs___1Mg5g {\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  justify-content: space-between;\n  flex-wrap: wrap; }\n\n.style__input-container___ULcL- {\n  width: 291px; }\n\n.style__plus-button-wrapper___2lBw4 {\n  display: flex;\n  flex-direction: column; }\n\n.style__add-buttons-container___1tIlM {\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  width: 163px;\n  justify-content: space-between; }\n\n.style__scenario-button___8TDmB {\n  width: 165px;\n  height: 38px;\n  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center; }\n\n.style__actions-style___3ha_3 {\n  cursor: pointer;\n  font-family: Roboto;\n  font-size: 14px;\n  font-weight: 500;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.62;\n  letter-spacing: normal;\n  text-align: center;\n  color: #eba205; }\n", ""]);

// Exports
exports.locals = {
	"top": "style__top___31smX",
	"bottom": "style__bottom___3m7K_",
	"top-inputs": "style__top-inputs___1Mg5g",
	"input-container": "style__input-container___ULcL-",
	"plus-button-wrapper": "style__plus-button-wrapper___2lBw4",
	"add-buttons-container": "style__add-buttons-container___1tIlM",
	"scenario-button": "style__scenario-button___8TDmB",
	"actions-style": "style__actions-style___3ha_3"
};