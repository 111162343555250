exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__main___1bXv4 {\n  width: 100%;\n  height: 100%;\n  margin: 20px auto 0;\n  text-align: center; }\n\n.style__panel___3K4FN {\n  display: inline-block;\n  width: 650px;\n  text-align: left; }\n\n.style__header___3Rdzz {\n  text-align: center;\n  padding: 5px;\n  color: #1d7ab4;\n  font-size: 36px; }\n\n.style__line___j1Wow {\n  display: flex;\n  flex-direction: row;\n  font-size: 18px; }\n\n.style__create-button___6GUcA {\n  width: 120px;\n  height: 35px !important;\n  margin-bottom: 10px;\n  padding: 0px !important; }\n", ""]);

// Exports
exports.locals = {
	"main": "style__main___1bXv4",
	"panel": "style__panel___3K4FN",
	"header": "style__header___3Rdzz",
	"line": "style__line___j1Wow",
	"create-button": "style__create-button___6GUcA"
};