var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import classnames from 'classnames';
import css from './Card.scss';
import colors from '../styles/_colors.scss';
export var CardTypes;
(function (CardTypes) {
    CardTypes["DEFAULT"] = "DEFAULT";
    CardTypes["CLICKER"] = "CLICKER";
    CardTypes["PLACEHOLDER"] = "PLACEHOLDER";
    CardTypes["ACTIVE"] = "ACTIVE";
    CardTypes["NEGATIVE"] = "NEGATIVE";
    CardTypes["POSITIVE"] = "POSITIVE";
    CardTypes["DISABLED"] = "DISABLED";
    CardTypes["SUCCESS"] = "SUCCESS";
    CardTypes["FAILED"] = "FAILED";
    CardTypes["ERROR"] = "ERROR";
    CardTypes["MESSAGE"] = "MESSAGE";
    CardTypes["INTERMEDIATE"] = "INTERMEDIATE";
    CardTypes["DROPDOWN"] = "DROPDOWN";
    CardTypes["LINE_INDICATOR"] = "LINE_INDICATOR";
})(CardTypes || (CardTypes = {}));
var Card = React.forwardRef(function (_a, ref) {
    var _b;
    var children = _a.children, _c = _a.type, type = _c === void 0 ? CardTypes.DEFAULT : _c, _d = _a.hover, hover = _d === void 0 ? false : _d, _e = _a.hovering, hovering = _e === void 0 ? false : _e, _f = _a.selected, selected = _f === void 0 ? false : _f, className = _a.className, _g = _a.clickable, clickable = _g === void 0 ? true : _g, _h = _a.indicatorColor, indicatorColor = _h === void 0 ? colors.defaultBlue : _h, _j = _a.style, style = _j === void 0 ? {} : _j, rest = __rest(_a, ["children", "type", "hover", "hovering", "selected", "className", "clickable", "indicatorColor", "style"]);
    var classes = classnames(css.card, className, (_b = {},
        _b[css['card--default']] = type === CardTypes.DEFAULT,
        _b[css['card--clicker']] = type === CardTypes.CLICKER,
        _b[css['card--placeholder']] = type === CardTypes.PLACEHOLDER,
        _b[css['card--active']] = type === CardTypes.ACTIVE,
        _b[css['card--disabled']] = type === CardTypes.DISABLED,
        _b[css['card--negative']] = type === CardTypes.NEGATIVE,
        _b[css['card--positive']] = type === CardTypes.POSITIVE,
        _b[css['card--success']] = type === CardTypes.SUCCESS,
        _b[css['card--failed']] = type === CardTypes.FAILED,
        _b[css['card--error']] = type === CardTypes.ERROR,
        _b[css['card--intermediate']] = type === CardTypes.INTERMEDIATE,
        _b[css['card--message']] = type === CardTypes.MESSAGE,
        _b[css['card--dropdown']] = type === CardTypes.DROPDOWN,
        _b[css['card--indicator']] = type === CardTypes.LINE_INDICATOR,
        _b));
    var cardStyle = type === CardTypes.LINE_INDICATOR
        ? __assign(__assign({}, style), { '--upper-line-color': indicatorColor }) : style;
    return (React.createElement("div", __assign({ ref: ref, className: classes }, rest, { "data-hoverable": hover, "data-hovering": hovering, "data-clickable": clickable, "data-selected": selected, style: cardStyle }),
        type === CardTypes.DISABLED && React.createElement("div", { className: css.card__disabled }),
        children));
});
Card.CARD_TYPES = CardTypes;
export default Card;
