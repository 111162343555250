exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__drawer___2pmF4 {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  flex-shrink: 0;\n  transition: margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1); }\n  .style__drawer___2pmF4.style__drawer--open___3Bh-B {\n    margin-left: 256px; }\n\n.style__menu-selected___3NI9O {\n  background: #242B57 !important; }\n\n.style__appbar___5lkmP {\n  background: transparent !important;\n  flex-shrink: 0; }\n\n.style__logo-text___WulHy {\n  font-weight: 700;\n  color: #ffffff;\n  font-family: Orbitron, sans-serif;\n  text-transform: lowercase;\n  margin: -4px 0 0 10px; }\n\n.style__logo-wrapper___SENfX {\n  display: flex;\n  align-items: center; }\n\n.style__icon___1qcEF {\n  color: white;\n  font-size: 20px; }\n", ""]);

// Exports
exports.locals = {
	"drawer": "style__drawer___2pmF4",
	"drawer--open": "style__drawer--open___3Bh-B",
	"menu-selected": "style__menu-selected___3NI9O",
	"appbar": "style__appbar___5lkmP",
	"logo-text": "style__logo-text___WulHy",
	"logo-wrapper": "style__logo-wrapper___SENfX",
	"icon": "style__icon___1qcEF"
};