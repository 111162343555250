exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".configurationColumn__header-time___1Yfqx {\n  word-wrap: break-word !important;\n  text-overflow: initial !important;\n  overflow: visible !important;\n  white-space: pre-wrap;\n  line-height: 1.5em; }\n\n.configurationColumn__action-style___3U9Yx {\n  color: #557eff;\n  cursor: pointer;\n  height: 46px;\n  display: flex;\n  align-items: center;\n  justify-content: center; }\n\n.configurationColumn__center-flex___1si7J {\n  display: flex;\n  align-items: center;\n  justify-content: center; }\n\n.configurationColumn__disabled-button___2kx4B {\n  color: #b4c9d3;\n  cursor: not-allowed; }\n\n.configurationColumn__icon___1-lGy {\n  font-size: 20px; }\n\n.configurationColumn__notes___1RZL2 p {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 164px; }\n", ""]);

// Exports
exports.locals = {
	"header-time": "configurationColumn__header-time___1Yfqx",
	"action-style": "configurationColumn__action-style___3U9Yx",
	"center-flex": "configurationColumn__center-flex___1si7J",
	"disabled-button": "configurationColumn__disabled-button___2kx4B",
	"icon": "configurationColumn__icon___1-lGy",
	"notes": "configurationColumn__notes___1RZL2"
};