exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Filter__filter-wrapper___2mtnc {\n  padding: 0 20px;\n  margin-bottom: 15px; }\n  .Filter__filter-wrapper___2mtnc .Filter__filter-input___xk5N0 {\n    width: calc(100% - 2px);\n    border: 0;\n    border-radius: 0;\n    padding: 0 0 15px;\n    border-bottom: 1px solid #557eff;\n    font-size: 13px; }\n    .Filter__filter-wrapper___2mtnc .Filter__filter-input___xk5N0:focus {\n      box-shadow: 0 0 0 2px rgba(68, 130, 255, 0);\n      outline: none; }\n    .Filter__filter-wrapper___2mtnc .Filter__filter-input___xk5N0::placeholder {\n      color: #c2c2c2; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"filter-wrapper": "Filter__filter-wrapper___2mtnc",
	"filter-input": "Filter__filter-input___xk5N0"
};