exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__box-wrapper___1AX0I {\n  padding: 10px 20px;\n  border-radius: 3px;\n  background-color: #fafbfc;\n  border: 1px solid #e9e9e9;\n  margin-left: 6px;\n  margin-top: 1px;\n  margin-bottom: 1px; }\n\n.style__box-title___yrIjk {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between; }\n\n.style__box-label___1-kTN {\n  font-weight: 500;\n  color: #778195; }\n\n.style__details___2EAc4 {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  font-size: 14px;\n  text-align: left; }\n\n.style__details-text___2ojVn {\n  font-size: 21px;\n  font-weight: 300;\n  color: #778195;\n  align-items: center;\n  display: flex;\n  justify-content: center; }\n", ""]);

// Exports
exports.locals = {
	"box-wrapper": "style__box-wrapper___1AX0I",
	"box-title": "style__box-title___yrIjk",
	"box-label": "style__box-label___1-kTN",
	"details": "style__details___2EAc4",
	"details-text": "style__details-text___2ojVn"
};