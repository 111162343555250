exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".index__disabled___-YqZo {\n  pointer-events: none; }\n\n.index__hidden___3fw-L {\n  visibility: hidden; }\n\n.index__collapsible___1kTxG {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  color: #778195;\n  margin-bottom: 10px; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"disabled": "index__disabled___-YqZo",
	"hidden": "index__hidden___3fw-L",
	"collapsible": "index__collapsible___1kTxG"
};