exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".header__header-wrapper___2xB27 {\n  display: flex;\n  align-items: center; }\n\n.header__header-text___2Z-KN {\n  position: relative;\n  color: #557eff;\n  font-size: 13px;\n  font-weight: 500;\n  margin-left: var(--header-padding);\n  margin-right: 0.2em; }\n\n.header__header-sorters___2KgT5 {\n  position: relative;\n  display: inline-flex;\n  margin-left: 10px;\n  flex-direction: column;\n  justify-content: center; }\n  .header__header-sorters___2KgT5 i {\n    font-size: 12px;\n    color: #dbdbdb;\n    position: absolute; }\n    .header__header-sorters___2KgT5 i:first-child {\n      top: -16px; }\n    .header__header-sorters___2KgT5 i:last-child {\n      top: -6px; }\n    .header__header-sorters___2KgT5 i[sorted=true] {\n      color: #557eff; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"header-wrapper": "header__header-wrapper___2xB27",
	"header-text": "header__header-text___2Z-KN",
	"header-sorters": "header__header-sorters___2KgT5"
};