import React from 'react';
import ReactDOM from 'react-dom';
import ReactResizeDetector from 'react-resize-detector';
import { Manager, Reference, Popper } from 'react-popper';
import classnames from 'classnames';
import { FadingTransition } from './transitions/Transitions';
import Placements from './PopoverPlacements.export';
import Arrow from './components/Arrow';
import css from './Popover.scss';
var modifiers = {
    preventOverflow: {
        boundariesElement: 'window',
        escapeWithReference: false
    }
};
var Popover = function (_a) {
    var children = _a.children, content = _a.content, _b = _a.placement, placement = _b === void 0 ? Placements.TopCenter : _b, _c = _a.offset, offset = _c === void 0 ? 0 : _c, _d = _a.isOpened, isOpened = _d === void 0 ? true : _d, _e = _a.eventsEnabled, eventsEnabled = _e === void 0 ? true : _e, _f = _a.pointerEvents, pointerEvents = _f === void 0 ? true : _f;
    return (React.createElement(Manager, null,
        React.createElement(Reference, null, function (_a) {
            var ref = _a.ref;
            return (React.createElement("div", { ref: ref, "data-test": 'target', className: css.target }, children));
        }),
        ReactDOM.createPortal(React.createElement(FadingTransition, { isOpened: isOpened },
            React.createElement(Popper, { placement: placement, eventsEnabled: eventsEnabled, modifiers: modifiers, positionFixed: true }, function (_a) {
                var _b;
                var ref = _a.ref, style = _a.style, placement = _a.placement, arrowProps = _a.arrowProps, scheduleUpdate = _a.scheduleUpdate;
                var popperStyle = Object.assign({}, style, { '--popper-offset': offset + "px" });
                return (React.createElement("div", { onClick: function (e) { return e.stopPropagation(); }, ref: ref, "data-test": 'popper-ref', className: classnames(css['ref-wrapper'], (_b = {}, _b[css['ref-wrapper--pointerEvents']] = pointerEvents, _b)), style: popperStyle },
                    React.createElement("div", { "data-placement": placement, className: css.popper },
                        React.createElement(ReactResizeDetector, { handleWidth: true, handleHeight: true, onResize: scheduleUpdate },
                            React.createElement("div", { className: css.popper__content, "data-test": 'popover-content' }, content)),
                        React.createElement(Arrow, { placement: placement, arrowProps: arrowProps }))));
            })), document.querySelector('body'))));
};
Popover.Placements = Placements;
export default Popover;
