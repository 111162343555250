var PopoverPlacements;
(function (PopoverPlacements) {
    PopoverPlacements["TopLeft"] = "top-start";
    PopoverPlacements["TopCenter"] = "top";
    PopoverPlacements["TopRight"] = "top-end";
    PopoverPlacements["RightUp"] = "right-start";
    PopoverPlacements["RightCenter"] = "right";
    PopoverPlacements["RightDown"] = "right-end";
    PopoverPlacements["BottomRight"] = "bottom-end";
    PopoverPlacements["BottomCenter"] = "bottom";
    PopoverPlacements["BottomLeft"] = "bottom-start";
    PopoverPlacements["LeftDown"] = "left-end";
    PopoverPlacements["LeftCenter"] = "left";
    PopoverPlacements["LeftUp"] = "left-start";
})(PopoverPlacements || (PopoverPlacements = {}));
export default PopoverPlacements;
