exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ErrorWrapper__error-wrapper___3c8hw {\n  width: 100%;\n  position: relative;\n  display: flex;\n  flex-direction: column; }\n\n.ErrorWrapper__error-text___2Q0K- {\n  position: absolute;\n  top: 100%;\n  font-size: 11px;\n  line-height: 11px;\n  color: #f9453e; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"error-wrapper": "ErrorWrapper__error-wrapper___3c8hw",
	"error-text": "ErrorWrapper__error-text___2Q0K-"
};