exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ScrollableTabsNavBar__scrolling-wrapper___1C7T7 {\n  width: 100%;\n  position: relative; }\n  .ScrollableTabsNavBar__scrolling-wrapper--no-scroll___2lDqS {\n    padding-left: 12px; }\n  .ScrollableTabsNavBar__scrolling-wrapper__bottom-line___2jSru {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    height: 0;\n    width: 100%;\n    margin: 0;\n    padding: 0;\n    border: none;\n    border-bottom: 1px solid #e9e9e9;\n    z-index: 100; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"button-width-size": "32",
	"no-scroll-padding": "12",
	"scrolling-wrapper": "ScrollableTabsNavBar__scrolling-wrapper___1C7T7",
	"scrolling-wrapper--no-scroll": "ScrollableTabsNavBar__scrolling-wrapper--no-scroll___2lDqS",
	"scrolling-wrapper__bottom-line": "ScrollableTabsNavBar__scrolling-wrapper__bottom-line___2jSru"
};