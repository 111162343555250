exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Section__disabled___1c43Q {\n  pointer-events: none; }\n\n.Section__hidden___1eB73 {\n  visibility: hidden; }\n\n.Section__sectionWrapper___qr3pl {\n  display: flex; }\n  .Section__sectionWrapper___qr3pl[data-border-right=true] {\n    border-right: solid 1px #e9e9e9; }\n  .Section__sectionWrapper___qr3pl[data-border-left=true] {\n    border-left: solid 1px #e9e9e9; }\n  .Section__sectionWrapper__content___3H3ZV {\n    display: flex;\n    align-items: center; }\n\n.Section__section___38giG {\n  position: relative;\n  display: flex;\n  color: #778195;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  user-select: none;\n  padding: 0px 20px;\n  width: auto;\n  animation-name: Section__section-appear___33b-g;\n  animation-duration: 0.5s;\n  animation-fill-mode: both; }\n\n@keyframes Section__section-appear___33b-g {\n  0% {\n    visibility: hidden;\n    opacity: 0; }\n  100% {\n    visibility: visible;\n    opacity: 1; } }\n\n.Section__section___38giG:hover {\n  z-index: 1; }\n\n.Section__icon___3QGxw {\n  height: 100%;\n  font-family: FontAwesome;\n  font-size: 14px;\n  align-items: center;\n  justify-content: center;\n  display: flex; }\n  .Section__icon___3QGxw:not(:last-child) {\n    margin-right: 9px; }\n\n.Section__text___2IWTf {\n  display: inline-flex; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"disabled": "Section__disabled___1c43Q",
	"hidden": "Section__hidden___1eB73",
	"sectionWrapper": "Section__sectionWrapper___qr3pl",
	"sectionWrapper__content": "Section__sectionWrapper__content___3H3ZV",
	"section": "Section__section___38giG",
	"section-appear": "Section__section-appear___33b-g",
	"icon": "Section__icon___3QGxw",
	"text": "Section__text___2IWTf"
};