exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Label__Label___1v8JQ {\n  font-weight: 600;\n  font-size: 14px;\n  color: #557eff;\n  line-height: normal; }\n  .Label__Label--pre___31R9l, .Label__Label--post___e6qsE {\n    font-weight: 400;\n    text-transform: lowercase; }\n  .Label__Label--post___e6qsE {\n    color: #778195; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"Label": "Label__Label___1v8JQ",
	"Label--pre": "Label__Label--pre___31R9l",
	"Label--post": "Label__Label--post___e6qsE"
};