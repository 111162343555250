exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__page___1fdCH {\n  height: 100%;\n  overflow: auto;\n  background-color: #f1f3f6; }\n\n.style__content___29u-N {\n  margin-top: 55px;\n  margin-left: 20px;\n  margin-right: 5px; }\n\n.style__title___lTMYF {\n  height: 24px;\n  font-size: 20px;\n  font-weight: 900;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  color: #555555; }\n\n.style__description___qLI0r {\n  max-width: 644px;\n  min-height: 46px;\n  font-size: 14px;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: 1.62;\n  letter-spacing: normal;\n  color: #778195;\n  margin-bottom: 43px;\n  margin-top: 20px; }\n", ""]);

// Exports
exports.locals = {
	"page": "style__page___1fdCH",
	"content": "style__content___29u-N",
	"title": "style__title___lTMYF",
	"description": "style__description___qLI0r"
};