exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ScrollButton__scroll-button-box___9M3hD {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 32px;\n  cursor: pointer;\n  flex: none;\n  position: absolute;\n  height: 100%;\n  top: 0;\n  z-index: 10; }\n  .ScrollButton__scroll-button-box-left___1wCDn {\n    left: 0;\n    background-image: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); }\n  .ScrollButton__scroll-button-box-right___7kHph {\n    right: 0;\n    background-image: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); }\n  .ScrollButton__scroll-button-box--disabled___1dcvR {\n    cursor: not-allowed; }\n  .ScrollButton__scroll-button-box__arrow___3_ps_ {\n    color: #557eff;\n    font-size: 10px; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"button-width-size": "32",
	"no-scroll-padding": "12",
	"scroll-button-box": "ScrollButton__scroll-button-box___9M3hD",
	"scroll-button-box-left": "ScrollButton__scroll-button-box-left___1wCDn",
	"scroll-button-box-right": "ScrollButton__scroll-button-box-right___7kHph",
	"scroll-button-box--disabled": "ScrollButton__scroll-button-box--disabled___1dcvR",
	"scroll-button-box__arrow": "ScrollButton__scroll-button-box__arrow___3_ps_"
};