exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Input__input___5vxBY {\n  transition: all 0.2s;\n  font-size: 13px;\n  width: 100%;\n  padding: 0 5px;\n  border-radius: 6px;\n  border: 1px solid #e9e9e9;\n  height: var(--input-height); }\n  .Input__input___5vxBY.Input__input--error___1OXAb {\n    border-color: #f9453e; }\n  .Input__input___5vxBY.Input__input--disabled___1YklX {\n    background: #fafafa; }\n  .Input__input___5vxBY:hover {\n    border-color: rgba(121, 194, 219, 0.27); }\n  .Input__input___5vxBY:active, .Input__input___5vxBY:focus, .Input__input___5vxBY:focus-within {\n    outline: none;\n    border-color: #557eff;\n    box-shadow: inset 0 0 5px 0 rgba(13, 145, 189, 0.25); }\n  .Input__input___5vxBY::placeholder {\n    color: #a8a8a8; }\n  .Input__input___5vxBY[type=number] {\n    -moz-appearance: textfield; }\n  .Input__input___5vxBY[type=number]::-webkit-inner-spin-button, .Input__input___5vxBY[type=number]::-webkit-outer-spin-button {\n    -webkit-appearance: none;\n    margin: 0; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"input": "Input__input___5vxBY",
	"input--error": "Input__input--error___1OXAb",
	"input--disabled": "Input__input--disabled___1YklX"
};