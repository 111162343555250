exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Checkbox__checkbox___2AreI {\n  width: 14px;\n  height: 14px;\n  border-radius: 2px;\n  flex-shrink: 0;\n  flex-grow: 0;\n  position: relative;\n  cursor: pointer;\n  font-size: 12px; }\n  .Checkbox__checkbox___2AreI input[type='checkbox'] {\n    cursor: inherit;\n    opacity: 0;\n    width: 14px;\n    height: 14px;\n    margin: 0;\n    position: absolute;\n    left: 0;\n    top: 0; }\n  .Checkbox__checkbox___2AreI.Checkbox__disabled___3NQZZ {\n    cursor: default;\n    background-color: #e9e9e9; }\n\n.Checkbox__checkbox--checked___39Epj {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: #557eff;\n  color: #ffffff; }\n  .Checkbox__checkbox--checked___39Epj.Checkbox__disabled___3NQZZ {\n    color: #c2c2c2; }\n\n.Checkbox__checkbox--unchecked___3njuv {\n  border: solid 1px #557eff;\n  background-color: #ffffff; }\n  .Checkbox__checkbox--unchecked___3njuv.Checkbox__disabled___3NQZZ {\n    border: solid 1px #dbdbdb; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"checkbox": "Checkbox__checkbox___2AreI",
	"disabled": "Checkbox__disabled___3NQZZ",
	"checkbox--checked": "Checkbox__checkbox--checked___39Epj",
	"checkbox--unchecked": "Checkbox__checkbox--unchecked___3njuv"
};