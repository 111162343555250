exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Chip__item___3I6s2 {\n  font-size: 13px;\n  text-align: left;\n  color: #555555;\n  display: inline-flex;\n  border: solid 1px #e9e9e9;\n  background-color: #f3f3f3;\n  border-radius: 3px;\n  align-items: center;\n  padding: 3px 6px;\n  white-space: pre-wrap;\n  word-wrap: break-word; }\n  .Chip__item___3I6s2 > .Chip__button-remove___xY6Pq {\n    color: #969696;\n    font-size: 10px;\n    cursor: pointer;\n    margin-left: 5px;\n    margin-bottom: 2px; }\n    .Chip__item___3I6s2 > .Chip__button-remove___xY6Pq:hover {\n      color: #545454; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"item": "Chip__item___3I6s2",
	"button-remove": "Chip__button-remove___xY6Pq"
};