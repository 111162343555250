exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".get-configuration__card-wrapper___14TWy {\n  margin-bottom: 80px; }\n\n.get-configuration__action-style___1pwBP {\n  color: #557eff;\n  cursor: pointer;\n  height: 46px;\n  display: flex;\n  align-items: center; }\n\n.get-configuration__disabled-button___26dr3 {\n  color: #b4c9d3;\n  cursor: not-allowed; }\n\n.get-configuration__icon___29egC {\n  font-size: 20px; }\n\n.get-configuration__configuration-item-wrapper___t_XZt {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  flex: 1; }\n", ""]);

// Exports
exports.locals = {
	"card-wrapper": "get-configuration__card-wrapper___14TWy",
	"action-style": "get-configuration__action-style___1pwBP",
	"disabled-button": "get-configuration__disabled-button___26dr3",
	"icon": "get-configuration__icon___29egC",
	"configuration-item-wrapper": "get-configuration__configuration-item-wrapper___t_XZt"
};