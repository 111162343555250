exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Tooltip__tooltip___3s17x {\n  position: relative; }\n  .Tooltip__tooltip__content___AwCq7 {\n    padding: 17px 20px;\n    line-height: 1.62;\n    white-space: pre;\n    max-height: 90vh;\n    overflow: auto; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"tooltip": "Tooltip__tooltip___3s17x",
	"tooltip__content": "Tooltip__tooltip__content___AwCq7"
};