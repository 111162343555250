exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".NumericPagination__wrapper___342EK {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 40px;\n  font-size: 12px;\n  color: #778195;\n  padding: 20px; }\n\n.NumericPagination__counter___1i9T- {\n  margin-right: 10px;\n  margin-left: 10px; }\n\n.NumericPagination__btn-text___2k4UE {\n  color: #c2c2c2;\n  font-size: 13px;\n  cursor: not-allowed;\n  user-select: none; }\n\n.NumericPagination__btn-enabled___aIzC8 {\n  color: #557eff;\n  cursor: pointer; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"wrapper": "NumericPagination__wrapper___342EK",
	"counter": "NumericPagination__counter___1i9T-",
	"btn-text": "NumericPagination__btn-text___2k4UE",
	"btn-enabled": "NumericPagination__btn-enabled___aIzC8"
};