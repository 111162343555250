var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import classNames from 'classnames';
import style from './InputText.scss';
export var InputTextTypes;
(function (InputTextTypes) {
    InputTextTypes["DEFAULT"] = "DEFAULT";
    InputTextTypes["PLACEHOLDER"] = "PLACEHOLDER";
    InputTextTypes["VIEW"] = "VIEW";
})(InputTextTypes || (InputTextTypes = {}));
var InputText = function (_a) {
    var _b;
    var _c = _a.className, className = _c === void 0 ? '' : _c, _d = _a.type, type = _d === void 0 ? InputTextTypes.DEFAULT : _d, rest = __rest(_a, ["className", "type"]);
    return (React.createElement("label", __assign({ className: classNames(style.InputText, (_b = {},
            _b[style['InputText--placeholder']] = type === InputTextTypes.PLACEHOLDER,
            _b[style['InputText--view']] = type === InputTextTypes.VIEW,
            _b), className) }, rest)));
};
InputText.TYPES = InputTextTypes;
export default InputText;
