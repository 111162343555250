exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".SideNote__SideNote___2W_-G {\n  font-weight: 500;\n  color: #778195;\n  line-height: 1.564rem;\n  font-size: 0.9666rem; }\n  .SideNote__SideNote--bold___1hrGz {\n    font-weight: 700; }\n  .SideNote__SideNote--obscure___2TTvw {\n    color: #a8a8a8; }\n  .SideNote__SideNote--disabled___1MklY {\n    color: #c2c2c2; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"SideNote": "SideNote__SideNote___2W_-G",
	"SideNote--bold": "SideNote__SideNote--bold___1hrGz",
	"SideNote--obscure": "SideNote__SideNote--obscure___2TTvw",
	"SideNote--disabled": "SideNote__SideNote--disabled___1MklY"
};