exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__wrapper___3Jz2R {\n  display: flex;\n  width: 100%;\n  height: 97px;\n  border-radius: 6px;\n  box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.05);\n  background-color: #ffffff;\n  align-items: center;\n  padding: 15px; }\n\n.style__input___2bDOE {\n  max-width: 99%; }\n\n.style__title-wrapper___2x3-5 {\n  flex: 1; }\n\n.style__button___1L8OG {\n  margin-top: 23px;\n  width: 100px;\n  height: 35px !important; }\n", ""]);

// Exports
exports.locals = {
	"wrapper": "style__wrapper___3Jz2R",
	"input": "style__input___2bDOE",
	"title-wrapper": "style__title-wrapper___2x3-5",
	"button": "style__button___1L8OG"
};