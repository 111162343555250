exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Arrows__arrow___2uBpo {\n  display: flex !important;\n  background: white;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n  width: 18px;\n  text-align: center;\n  font-size: 12px !important;\n  border-left: none;\n  cursor: not-allowed; }\n  .Arrows__arrow___2uBpo:active {\n    background-color: #dce0e0; }\n\n.Arrows__up-arrow___ngL2q {\n  border-top-right-radius: 3px;\n  border-bottom: none; }\n\n.Arrows__enabled___2_wJE {\n  color: #557eff;\n  cursor: pointer; }\n\n.Arrows__down-arrow___1xVd0 {\n  border-top: 1px solid #e9e9e9;\n  border-bottom-right-radius: 3px; }\n\n.Arrows__arrows-wrapper___3qNes {\n  display: flex;\n  flex-direction: column; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"arrow": "Arrows__arrow___2uBpo",
	"up-arrow": "Arrows__up-arrow___ngL2q",
	"enabled": "Arrows__enabled___2_wJE",
	"down-arrow": "Arrows__down-arrow___1xVd0",
	"arrows-wrapper": "Arrows__arrows-wrapper___3qNes"
};