exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".InputText__InputText___1C-1O {\n  font-weight: 400;\n  font-size: 1rem;\n  color: #555555;\n  letter-spacing: normal;\n  line-height: 1.6183rem; }\n  .InputText__InputText--placeholder___3bpMh {\n    font-weight: 400;\n    font-size: 1rem;\n    color: #555555;\n    letter-spacing: normal;\n    color: #a8a8a8; }\n  .InputText__InputText--view___3J6xr {\n    font-weight: 400;\n    font-size: 1rem;\n    color: #555555;\n    letter-spacing: normal;\n    color: #778195; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"InputText": "InputText__InputText___1C-1O",
	"InputText--placeholder": "InputText__InputText--placeholder___3bpMh",
	"InputText--view": "InputText__InputText--view___3J6xr"
};