exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".UiSwitcher__switch-container___3Jkcr[data-active=\"true\"] .UiSwitcher__switch-ball___1FzIL {\n  transform: translate3d(var(--switch-space), 0, 0);\n  background-color: #ffffff; }\n\n.UiSwitcher__switch-ball___1FzIL {\n  width: var(--ball-size);\n  height: var(--ball-size); }\n\ninput[type=\"checkbox\"].UiSwitcher__switch___1f19F {\n  position: absolute;\n  cursor: pointer;\n  opacity: 0; }\n\ninput[type=\"checkbox\"].UiSwitcher__switch___1f19F + div {\n  vertical-align: middle;\n  border-radius: 999px;\n  -webkit-transition-duration: .4s;\n  transition-duration: .4s;\n  -webkit-transition-property: background-color, box-shadow;\n  transition-property: background-color, box-shadow;\n  cursor: pointer; }\n\n.UiSwitcher__onColor___2BqmA {\n  background-color: #a3a8a5; }\n\n.UiSwitcher__offColor___1pxFy {\n  background-color: #a3a8a5; }\n\ninput[type=\"checkbox\"].UiSwitcher__switch___1f19F:checked + div {\n  background-position: 0 0; }\n\ninput[type=\"checkbox\"].UiSwitcher__switch___1f19F:checked + div {\n  background-color: #557EFF; }\n\ninput[type=\"checkbox\"].UiSwitcher__switch___1f19F + div > div {\n  float: left;\n  border-radius: inherit;\n  background: #ffffff;\n  transition-timing-function: cubic-bezier(1, 0, 0, 1);\n  transition-duration: 0.4s;\n  transition-property: transform, background-color;\n  pointer-events: none;\n  margin-top: 1px;\n  margin-left: 1px; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"switch-container": "UiSwitcher__switch-container___3Jkcr",
	"switch-ball": "UiSwitcher__switch-ball___1FzIL",
	"switch": "UiSwitcher__switch___1f19F",
	"onColor": "UiSwitcher__onColor___2BqmA",
	"offColor": "UiSwitcher__offColor___1pxFy"
};