exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".TextArea__textarea___2O1UJ {\n  transition: border 0.2s, background 0.2s, box-shadow 0.2s;\n  font-size: 13px;\n  width: 100%;\n  padding: 0 5px;\n  padding-top: 8px;\n  padding-bottom: 8px;\n  border-radius: 6px;\n  border: 1px solid #e9e9e9;\n  height: var(--textarea-height);\n  min-height: var(--textarea-height);\n  resize: none; }\n  .TextArea__textarea___2O1UJ.TextArea__textarea--error___38R4Y {\n    border-color: #f9453e; }\n  .TextArea__textarea___2O1UJ.TextArea__textarea--disabled___16vjV {\n    background: #fafafa; }\n  .TextArea__textarea___2O1UJ:hover {\n    border-color: rgba(121, 194, 219, 0.27); }\n  .TextArea__textarea___2O1UJ:active, .TextArea__textarea___2O1UJ:focus, .TextArea__textarea___2O1UJ:focus-within {\n    outline: none;\n    border-color: #557eff;\n    box-shadow: inset 0 0 5px 0 rgba(13, 145, 189, 0.25); }\n  .TextArea__textarea___2O1UJ::placeholder {\n    color: #a8a8a8; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"textarea": "TextArea__textarea___2O1UJ",
	"textarea--error": "TextArea__textarea--error___38R4Y",
	"textarea--disabled": "TextArea__textarea--disabled___16vjV"
};