exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".NumericInput__input-counter___p1sh6 {\n  flex: 1;\n  width: 0;\n  height: 100%;\n  border-radius: 3px 0 0 3px;\n  background-color: transparent;\n  text-align: center;\n  border: none;\n  border-right: 1px solid #e9e9e9; }\n  .NumericInput__input-counter___p1sh6:active, .NumericInput__input-counter___p1sh6:focus {\n    outline: none; }\n\n.NumericInput__input-counter-wrapper___2Zvu6 {\n  display: flex;\n  width: var(--number-input-width);\n  height: var(--number-input-height);\n  background: white;\n  border-radius: 6px;\n  border: 1px solid #e9e9e9; }\n  .NumericInput__input-counter-wrapper___2Zvu6[data-error=\"true\"] {\n    border-color: #f9453e; }\n  .NumericInput__input-counter-wrapper___2Zvu6:hover {\n    border-color: rgba(121, 194, 219, 0.27); }\n  .NumericInput__input-counter-wrapper___2Zvu6:focus-within {\n    border-color: #557eff;\n    box-shadow: inset 0 0 5px 0 rgba(13, 145, 189, 0.25); }\n  .NumericInput__input-counter-wrapper___2Zvu6[data-disabled=\"true\"] {\n    pointer-events: none; }\n    .NumericInput__input-counter-wrapper___2Zvu6[data-disabled=\"true\"] .NumericInput__input-counter___p1sh6 {\n      background: #fafafa; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"input-counter": "NumericInput__input-counter___p1sh6",
	"input-counter-wrapper": "NumericInput__input-counter-wrapper___2Zvu6"
};