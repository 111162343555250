exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__top___22y3X {\n  border-bottom: 1px solid #e9e9e9;\n  padding: 10px 20px;\n  display: flex;\n  justify-content: space-between; }\n\n.style__bottom___2GxoV {\n  padding: 10px 20px 10px 0px;\n  display: flex;\n  justify-content: flex-start;\n  overflow: auto;\n  height: 50%; }\n\n.style__top-inputs___33fgu {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  flex: 1;\n  justify-content: space-between; }\n\n.style__input-container___3Rktf {\n  display: flex;\n  justify-content: space-between;\n  width: 350px;\n  align-items: center; }\n\n.style__plus-button-wrapper___113R5 {\n  display: flex;\n  flex-direction: column; }\n\n.style__add-buttons-container___7flwh {\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  width: 163px;\n  justify-content: space-between; }\n\n.style__scenario-button___wztVF {\n  width: 165px;\n  height: 38px;\n  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center; }\n", ""]);

// Exports
exports.locals = {
	"top": "style__top___22y3X",
	"bottom": "style__bottom___2GxoV",
	"top-inputs": "style__top-inputs___33fgu",
	"input-container": "style__input-container___3Rktf",
	"plus-button-wrapper": "style__plus-button-wrapper___113R5",
	"add-buttons-container": "style__add-buttons-container___7flwh",
	"scenario-button": "style__scenario-button___wztVF"
};