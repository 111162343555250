exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".MultiValueInput__wrapper___3GhKB {\n  width: 100%;\n  position: relative;\n  line-height: 18px; }\n  .MultiValueInput__wrapper___3GhKB:focus, .MultiValueInput__wrapper___3GhKB:focus-within {\n    border-color: #557eff;\n    box-shadow: inset 0 0 5px 0 rgba(13, 145, 189, 0.25); }\n\n.MultiValueInput__wrapper___3GhKB {\n  border-radius: 6px;\n  border: 1px solid #e9e9e9;\n  width: 100%;\n  background-color: #ffffff;\n  display: flex;\n  flex-flow: row wrap;\n  align-items: center;\n  padding: 0 5px 3px 5px;\n  position: relative;\n  min-height: 35px; }\n  .MultiValueInput__wrapper___3GhKB > .MultiValueInput__list-item___qNGvi {\n    margin-top: 3px;\n    margin-right: 5px; }\n  .MultiValueInput__wrapper___3GhKB[disable=true] {\n    pointer-events: none; }\n\n.MultiValueInput__placeholder___2m8MW {\n  color: #a8a8a8;\n  pointer-events: none;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%); }\n\n.MultiValueInput__input-field___1S2-B {\n  transform: translateY(2px);\n  margin: 0;\n  border: none;\n  background-color: transparent;\n  box-shadow: none;\n  height: 25px;\n  flex: 1; }\n  .MultiValueInput__input-field___1S2-B:focus, .MultiValueInput__input-field___1S2-B:active {\n    outline: none;\n    box-shadow: none; }\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#ffffff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"wrapper": "MultiValueInput__wrapper___3GhKB",
	"list-item": "MultiValueInput__list-item___qNGvi",
	"placeholder": "MultiValueInput__placeholder___2m8MW",
	"input-field": "MultiValueInput__input-field___1S2-B"
};